import { ThemeV4Fragment } from '@codegen/cmsUtils';
import { ThemeShapes } from '@ui-v2/types/borders';

export const buildShapes = (theme: ThemeV4Fragment): ThemeShapes => {
  const { shapeV4 } = theme;

  return {
    none: 0,
    borderRadiusXs: shapeV4.borderRadiusXs,
    borderRadiusS: shapeV4.borderRadiusS,
    borderRadiusM: shapeV4.borderRadiusM,
    borderRadiusL: shapeV4.borderRadiusL,
    borderRadiusXl: shapeV4.borderRadiusXl,
    borderRadiusXxl: shapeV4.borderRadiusXxl,
    buttonPrimaryBorderRadius: shapeV4.buttonPrimaryBorderRadius,
    buttonSecondaryBorderradius: shapeV4.buttonSecondaryBorderradius,
    buttonTertiaryBorderRadius: shapeV4.buttonTertiaryBorderRadius,
    searchWidgetBorderRadius: shapeV4.searchWidgetBorderRadius,
    circle: '100%',
  };
};
