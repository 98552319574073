import { ThemeZIndex } from '@ui-v2/types/layout';

export const zIndices: ThemeZIndex = {
  '0': 0,
  '1': 1,
  '2': 2,
  '3': 3,
  header: 10,
  mobileFooter: 101,
  modal: 102,
  tooltips: 103,
};
