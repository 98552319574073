import { useGetServiceConfigQuery } from '@codegen/cmsUtils';
import { useConstants } from '../ConstantContext';

export const useServiceConfig = () => {
  const { locale } = useConstants();
  const { partner } = useConstants();
  const { data, isLoading } = useGetServiceConfigQuery({
    partner,
    locale,
  });

  const serviceConfig = {
    luggageCabin:
      data?.partnerConfig?.luggageCabin || data?.defaultConfig?.luggageCabin,
    luggagePersonal:
      data?.partnerConfig?.luggagePersonal ||
      data?.defaultConfig?.luggagePersonal,
    luggageChecked:
      data?.partnerConfig?.luggageChecked ||
      data?.defaultConfig?.luggageChecked,
    base: data?.partnerConfig?.base || data?.defaultConfig?.base,
    commission:
      data?.partnerConfig?.commission || data?.defaultConfig?.commission,
    premium: data?.partnerConfig?.premium || data?.defaultConfig?.premium,
  };

  const baseTierName = serviceConfig.base?.name.value ?? null;
  // This is a safe cast since the commission tier is always present in the default config
  const commissionTierName = serviceConfig.commission?.name.value as string;
  // This is a safe cast since the premium tier is always present in the default config
  const premiumTierName = serviceConfig.premium?.name.value as string;

  return {
    luggageCabin: serviceConfig.luggageCabin ?? null,
    luggagePersonal: serviceConfig.luggagePersonal ?? null,
    luggageChecked: serviceConfig.luggageChecked ?? null,
    baseTierName,
    commissionTierName,
    premiumTierName,
    premiumTierId: serviceConfig.premium?.serviceId as string,
    isLoading,
  };
};
