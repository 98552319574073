import { useMemo } from 'react';
import { useGetBookingConfigQuery } from '@codegen/cmsUtils';
import { RUNTIME_ENV } from '@shared/types/enums';
import { useConstants } from '../ConstantContext';
import { useExperiments } from '../ExperimentContext';
import { PaymentMethod } from '../utils/paymentContextUtils';

const useBookingConfig = () => {
  const { locale, partner } = useConstants();

  const { data } = useGetBookingConfigQuery({ partner, locale });
  const { experimentData } = useExperiments();
  const bookingConfig =
    experimentData?.bookingConfig ?? data?.partner?.partnerBookingConfig;

  const runtimeEnv = process.env.NEXT_PUBLIC_RUNTIME_ENV || RUNTIME_ENV.dev;
  const steps = useMemo(
    () =>
      (bookingConfig?.bookingSteps ?? []).filter(
        (step) => !step.environmentBlacklist?.includes(runtimeEnv),
      ),
    [bookingConfig?.bookingSteps, runtimeEnv],
  );

  const hideTaxBreakdown = bookingConfig?.hideTaxBreakdown ?? false;
  const showSherpaOnConfirmationPage =
    bookingConfig?.showSherpaOnConfirmationPage ?? false;

  const showTypeformOnConfirmationPage =
    bookingConfig?.showTypeformOnConfirmationPage ?? false;

  const showHotelsAndCarsOnConfirmationPage =
    bookingConfig?.showHotelsAndCarsOnConfirmationPage ?? false;

  const countryBlocklist =
    data?.partner?.partnerBookingConfig?.countryBlocklist ?? [];

  return {
    steps,
    showSherpaOnConfirmationPage,
    showTypeformOnConfirmationPage,
    showHotelsAndCarsOnConfirmationPage,
    hideTaxBreakdown,
    countryBlocklist,
    useAlignedBundles: bookingConfig?.useAlignedBundles ?? false,
    paymentMethods: (bookingConfig?.paymentMethods as
      | PaymentMethod[]
      | undefined) ?? [PaymentMethod.PCIProxyCreditCard],
  };
};

export default useBookingConfig;
