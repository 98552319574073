import dynamic from 'next/dynamic';
import { useGetPartnerMaintainceModeQuery } from '@codegen/cmsUtils';
import { useConstants } from '@web/context/ConstantContext';

const MaintenanceBanner = dynamic(
  () => import('@ui/components/MaintenanceBanner/MaintenanceBanner'),
  {
    ssr: false,
    loading: () => null,
  },
);

const MaintainceBannerContainer = () => {
  const { partner } = useConstants();
  const { data } = useGetPartnerMaintainceModeQuery({ partner });

  if (!data?.allMaintenances) {
    return null;
  }

  const maintainceToggle = data.allMaintenances.find((mode) => mode.on);

  if (!maintainceToggle) {
    return null;
  }

  return <MaintenanceBanner text={maintainceToggle.text.value} />;
};

export default MaintainceBannerContainer;
