import { z } from 'zod';
import {
  BookingStepFragment,
  PassengerRulesFragment,
  PassengersSectionFragment,
} from '@codegen/cmsUtils';
import {
  BillingAddress,
  ContactInformation,
  Passenger,
  OfferResponse,
} from '@codegen/offerAPI';
import { PassengerFields, ServiceClass } from '@shared/types/enums';
import { TranslateCmsString } from '@utils/hooks/useCmsTranslation';
import { BillingAddressValidations } from './billingAddressUtils';
import { hasServiceMismatch } from './bookingUtils';
import { ContactValidations } from './contactUtils';
import {
  checkIfUniqueNames,
  filterOutPassportIfNotNeeded,
  PassengerValidations,
} from './passengerUtils';
import {
  BaggageFormType,
  BillingAddressFormType,
  BillingInformationFormType,
  ContactInformationFormType,
  getZodBaggageObject,
  getZodBillingAddressObject,
  getZodBillingInformationObject,
  getZodContactObject,
  getZodPassengersObject,
  PassengersFormType,
} from './zodUtils';

export const MISMATCH_SERVICE_CLASSES = [ServiceClass.LUGGAGE_CHECKED];

export const PASSENGER_FIELDS_WITHOUT_PASSPORT = [
  PassengerFields.GENDER,
  PassengerFields.TITLE,
  PassengerFields.FIRST_NAME,
  PassengerFields.LAST_NAME,
  PassengerFields.DATE_OF_BIRTH,
  PassengerFields.PASSENGER_TYPE,
];

export const PASSPORT_PASSENGER_FIELDS = [
  PassengerFields.PASSPORT_COUNTRY,
  PassengerFields.PASSPORT_EXPIRATION,
  PassengerFields.PASSPORT_NUMBER,
  PassengerFields.FREQUENT_FLYER,
];

export const PASSENGER_FIELDS = [
  ...PASSENGER_FIELDS_WITHOUT_PASSPORT,
  ...PASSPORT_PASSENGER_FIELDS,
];

export const getPassengerFieldsToShow = ({
  passengerFields,
  passportRequired,
}: {
  passengerFields: Maybe<string[]>;
  passportRequired: boolean;
}) => {
  // filter out fields that are not specifically set in the CMS per partner
  const filteredPassengerFields = [
    ...PASSENGER_FIELDS.filter((field) => passengerFields?.includes(field)),
  ];

  // filter out the passport fields if they are not needed for this o&d
  return filterOutPassportIfNotNeeded(
    filteredPassengerFields,
    passportRequired,
  );
};

export const getPassengerSectionSchema = ({
  enforceUniqueFullName,
  lastFlightDate,
  passengerValidations,
  passportRequired,
  section,
  t,
  vendorPassengerRules,
}: {
  enforceUniqueFullName?: boolean;
  lastFlightDate?: string;
  passengerValidations: PassengerValidations;
  passportRequired: boolean;
  section: PassengersSectionFragment;
  t: TranslateCmsString;
  vendorPassengerRules: PassengerRulesFragment;
}) => {
  const passengerFieldsToShow = getPassengerFieldsToShow({
    passengerFields: section.passengerFields,
    passportRequired,
  });

  return z.object({
    passengers: z
      .array(
        getZodPassengersObject({
          t,
          validations: passengerValidations,
          passengerFieldsToShow,
          lastFlightDate,
          vendorPassengerRules,
        }),
      )
      .refine(
        (passengers) =>
          checkIfUniqueNames(passengers) || !enforceUniqueFullName,
        {
          message: t('duplicate_names_error', 'Full names must be unique'),
        },
      ),
  });
};

export const getFormSchemaFromSections = ({
  billingAddressValidations,
  contactValidations,
  enforceUniqueFullName,
  lastFlightDate,
  passengerValidations,
  passportRequired,
  sections,
  t,
  vendorPassengerRules,
}: {
  billingAddressValidations: BillingAddressValidations;
  contactValidations: ContactValidations;
  enforceUniqueFullName?: boolean;
  lastFlightDate?: string;
  passengerValidations: PassengerValidations;
  passportRequired: boolean;
  sections: BookingStepFragment['sections'];
  t: TranslateCmsString;
  vendorPassengerRules: PassengerRulesFragment;
}) => {
  return sections.reduce<z.AnyZodObject>((acc, section) => {
    switch (section.__typename) {
      case 'PassengersSectionRecord':
        return acc.merge(
          getPassengerSectionSchema({
            t,
            section,
            passengerValidations,
            lastFlightDate,
            vendorPassengerRules,
            passportRequired,
            enforceUniqueFullName,
          }),
        );
      case 'ContactSectionRecord':
        return acc.merge(
          getZodContactObject({ t, validations: contactValidations }),
        );
      case 'BillingAddressSectionRecord':
        return acc.merge(
          getZodBillingAddressObject({
            t,
            validations: billingAddressValidations,
          }),
        );
      case 'BaggageSectionRecord':
        return acc.merge(getZodBaggageObject());
      case 'BillingInformationSectionRecord':
        return acc.merge(getZodBillingInformationObject());
      default:
        return acc;
    }
  }, z.object({}));
};

export const getPassengersDefaultValues = ({
  passengers,
  residency,
}: {
  passengers: Passenger[];
  residency: string;
}) =>
  passengers.map((pax) => ({
    ...pax,
    // If no passport country is set, use the default residency
    [PassengerFields.PASSENGER_TYPE]: pax.expected_type,
    [PassengerFields.PASSPORT_COUNTRY]: pax.passport_country || residency,
  }));

export const getBillingAddressDefaultValues = ({
  billingAddress,
  residency,
}: {
  billingAddress: BillingAddress;
  residency: string;
}) => ({
  ...billingAddress,
  // If no country is set, use the default residency
  country: billingAddress.country || residency,
});

export const getDefaultValues = ({
  billingAddress,
  contact,
  defaultValues,
  offer,
  passengers,
  sections,
}: {
  billingAddress: BillingAddress;
  contact: ContactInformation;
  defaultValues: {
    phonePrefix: string | null;
    residency: string;
  };
  offer?: Maybe<OfferResponse>;
  passengers: Passenger[];
  sections: BookingStepFragment['sections'];
}) => {
  return sections.reduce<
    Partial<PassengersFormType> &
      Partial<ContactInformationFormType> &
      Partial<BillingAddressFormType> &
      Partial<BaggageFormType> &
      Partial<BillingInformationFormType>
  >((acc, section) => {
    switch (section.__typename) {
      case 'PassengersSectionRecord':
        return {
          ...acc,
          passengers: getPassengersDefaultValues({
            passengers,
            residency: defaultValues.residency,
          }),
        };
      case 'ContactSectionRecord':
        return {
          ...acc,
          contact: {
            ...contact,
            phone_country_code:
              // If the contact info country code is set to an empty string; use the default country code
              contact.phone_country_code || (defaultValues.phonePrefix ?? ''),
          },
        };
      case 'BillingAddressSectionRecord':
        return {
          ...acc,
          billingAddress: getBillingAddressDefaultValues({
            billingAddress,
            residency: defaultValues.residency,
          }),
        };
      case 'BaggageSectionRecord':
        return {
          ...acc,
          baggage: {
            hasServiceMismatch: hasServiceMismatch({
              offer,
              serviceClasses: MISMATCH_SERVICE_CLASSES,
            }),
          },
        };
      case 'BillingInformationSectionRecord':
        return {
          ...acc,
          billingInformation: {
            termsAccepted: false,
          },
        };

      default:
        return acc;
    }
  }, {});
};
