import { TierName } from '@codegen/offerAPI';

export const scrollToProtection = () => {
  if (typeof document === 'undefined') {
    return;
  }

  const input = document.querySelector(
    'button[data-cy=continue-button-service-tier-premium]',
  );

  input?.scrollIntoView({ behavior: 'smooth', block: 'center' });
};

export const getTierNameFromId = ({
  baseTierName,
  commissionTierName,
  premiumTierName,
  tierId,
}: {
  baseTierName?: string | null;
  commissionTierName: string;
  premiumTierName: string;
  tierId: TierName;
}) => {
  switch (tierId) {
    case 'base':
      return baseTierName as string;
    case 'premium':
      return premiumTierName;
    case 'commission':
      return commissionTierName;
    default:
      return premiumTierName;
  }
};

export const getTierDeltaFromSelectedTierPrice = ({
  numberOfPassengers,
  selectedTierPrice,
  tierPrice,
}: {
  numberOfPassengers: number;
  selectedTierPrice: number;
  tierPrice: number;
}) => {
  const deltaAmount = selectedTierPrice - tierPrice;
  const deltaAmountPerPassenger = deltaAmount / numberOfPassengers;

  return deltaAmountPerPassenger;
};
