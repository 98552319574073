import { TranslateCmsString } from '@utils/hooks/useCmsTranslation';

export const normalizeDate = (value: string) => {
  // Remove all non-digit characters
  const numericValue = value.replace(/[^0-9]+/g, '');

  // Check if the length indicates a MMYYYY format
  if (numericValue.length === 6) {
    // Extract MM from the start and the last two digits of YYYY
    return numericValue.substring(0, 2) + '/' + numericValue.substring(4, 6);
  }

  // For MMYY format, or any other format, proceed as before but ensure it doesn't exceed 5 characters
  return (
    numericValue
      .match(/.{1,2}/g)
      ?.join('/')
      .substring(0, 5) || ''
  );
};

export const FORMAT_ERROR = 'Please enter date in the correct format: MM/YY';

export const INVALID_DATE = 'Invalid date';

export const EXPIRED_ERROR = 'Date has expired';

export const NO_ERROR = '';

export const validateDate = (
  t: TranslateCmsString,
  expiryDate: string,
): string => {
  // Check if format is correct
  if (expiryDate.length !== 5) {
    return t(FORMAT_ERROR, FORMAT_ERROR);
  }
  const expiryMonth = parseInt(expiryDate.slice(0, 2));

  // Check if month is valid
  if (expiryMonth > 12) {
    return t(INVALID_DATE, INVALID_DATE);
  }

  // This will break when expiry date exceeds the year 2099
  const expiryYear = 2000 + parseInt(expiryDate.slice(-2));
  const todayDate = new Date();
  const fullExpiryDate = new Date();
  fullExpiryDate.setFullYear(expiryYear, expiryMonth, 0);

  // Check if date has expired
  if (fullExpiryDate < todayDate) {
    return t(EXPIRED_ERROR, EXPIRED_ERROR);
  }

  return NO_ERROR;
};
