import Head from 'next/head';
import { Global, useTheme } from '@emotion/react';
import { cssReset } from '@ui/styles/cssReset';
import { getDefaults } from '@ui/styles/defaults';
import { getPreloadLinks, getFonts } from '@ui/styles/fonts/fontUtils';
import { useConstants } from '@web/context/ConstantContext';
import usePartnerInfo from '@web/context/hooks/usePartnerInfo';

const HeadTags = () => {
  const theme = useTheme();
  const { partner } = useConstants();
  const { favicon } = usePartnerInfo();

  return (
    <>
      <Head>
        {favicon && <link href={favicon.url} rel="shortcut icon" />}
        {getPreloadLinks(partner)}
      </Head>
      <Global styles={cssReset} />
      <Global styles={getFonts(partner)} />
      <Global styles={getDefaults(theme)} />
    </>
  );
};

export default HeadTags;
