import dayjs from 'dayjs';
import { Vendors } from '@codegen/cmsTypes';
import {
  FieldValidationFragment,
  PassengerRulesFragment,
  PassengerTitlesFragment,
  RegexFragment,
  PassengerTitleOptionFragment,
  PassengerGendersFragment,
} from '@codegen/cmsUtils';
import { Leg, Passenger, PaxGender, PaxType } from '@codegen/offerAPI';
import { DropdownValue } from '@shared/types/dropdownTypes';
import { CMSPassengerType, PassengerFields } from '@shared/types/enums';
import { generateNumberArray } from '@utils/arrayUtils';
import { getDropdownValue } from '@utils/dropdownUtils';
import { createAnArray } from '@utils/helperUtils';
import { TranslateCmsString } from '@utils/hooks/useCmsTranslation';
import {
  getPaxTypeMaxAge,
  getPaxTypeMinAge,
} from '../search/searchWidgetUtils';

export type FieldValidation = {
  maxLength: number;
  minLength: number;
  regex: RegexFragment[];
  required: boolean;
};

export type PassengerValidations = {
  dateOfBirth: FieldValidation;
  firstName: FieldValidation;
  fullName: FieldValidation;
  lastName: FieldValidation;
  title: FieldValidation;
};

const DEFAULT_MAX_LENGTH = 30;

interface PassengerTitleOptionWithPaxGender
  extends PassengerTitleOptionFragment {
  gender: PaxGender;
}

interface PassengerTitlesWithPaxGender extends PassengerTitlesFragment {
  options: PassengerTitleOptionWithPaxGender[];
}

const getDefaultPassengerValidation = (
  t: TranslateCmsString,
): PassengerValidations => ({
  title: {
    required: true,
    minLength: 0,
    maxLength: DEFAULT_MAX_LENGTH,
    regex: [],
  },
  firstName: {
    required: true,
    minLength: 1,
    maxLength: DEFAULT_MAX_LENGTH,
    regex: [
      {
        pattern: '[^a-zA-Z\\s]',
        errorMessage: {
          __typename: 'ApplicationStringRecord' as const,
          value: t(
            'Invalid characters',
            'This field contains invalid characters',
          ),
          stringId: '',
          id: '',
        },
      },
    ],
  },
  lastName: {
    required: true,
    minLength: 2,
    maxLength: DEFAULT_MAX_LENGTH,
    regex: [
      {
        pattern: '[^a-zA-Z\\s]',
        errorMessage: {
          __typename: 'ApplicationStringRecord' as const,
          value: t(
            'Invalid characters',
            'This field contains invalid characters',
          ),
          stringId: '',
          id: '',
        },
      },
    ],
  },
  fullName: {
    required: false,
    minLength: 0,
    maxLength: DEFAULT_MAX_LENGTH,
    regex: [],
  },
  dateOfBirth: {
    required: true,
    minLength: 0,
    maxLength: DEFAULT_MAX_LENGTH,
    regex: [],
  },
});

export const constructLoadingPassengers = ({
  currentPassengers,
  numberOfAdults = '1',
  passengerTitles,
  vendorPassengerRules,
  youngstersAges,
}: {
  currentPassengers: Passenger[];
  numberOfAdults?: string;
  passengerTitles: {
    [key: string]: DropdownValue[];
  };
  vendorPassengerRules: PassengerRulesFragment;
  youngstersAges?: string;
}): Passenger[] => {
  return [
    // creating an array of passengers.
    // We do not know the true passenger type so we assume every
    // passenger is an adult until we get response from the booking server
    ...createAnArray(Number(numberOfAdults)).map((_, index) =>
      currentPassengers[index]
        ? (currentPassengers[index] as Passenger)
        : {
            passenger_id: String(index),
            title:
              (passengerTitles[PaxType['a']]?.[0]?.value as
                | string
                | undefined) || '',
            expected_type: PaxType['a'],
          },
    ),
    ...(youngstersAges
      ? youngstersAges.split('-').map((age: string, index) => {
          if (currentPassengers[index + Number(numberOfAdults)]) {
            return currentPassengers[
              index + Number(numberOfAdults)
            ] as Passenger;
          }

          const paxType =
            Number(age) <=
            (vendorPassengerRules.rules.find(
              (rule) => rule.passengerType === CMSPassengerType.INFANT,
            )?.maxAge ?? 2)
              ? PaxType['i']
              : PaxType['c'];

          return {
            passenger_id: (Number(numberOfAdults) + index).toString(),
            title:
              (passengerTitles[paxType]?.[0]?.value as string | undefined) ||
              '',
            expected_type: paxType,
          };
        })
      : []),
  ];
};

export const getFieldValidation = (
  currentField: FieldValidation,
  field?: Maybe<FieldValidationFragment>,
) => {
  if (!field) {
    return currentField;
  }

  return {
    required: currentField.required
      ? currentField.required
      : field.required ?? false,
    minLength:
      currentField.minLength > (field.minLength || 0)
        ? currentField.minLength
        : field.minLength || 0,
    maxLength:
      currentField.maxLength < (field.maxLength || DEFAULT_MAX_LENGTH)
        ? currentField.maxLength
        : field.maxLength || DEFAULT_MAX_LENGTH,
    regex: [...currentField.regex, ...field.regex],
  };
};

export const getPassengerValidations = ({
  t,
  vendors,
}: {
  t: TranslateCmsString;
  vendors: Vendors;
}) => {
  return Object.values(vendors).reduce<PassengerValidations>(
    (acc, { passengerValidation }) => {
      if (!passengerValidation) {
        return acc;
      }

      return {
        title: getFieldValidation(acc.title, passengerValidation.title),
        firstName: getFieldValidation(
          acc.firstName,
          passengerValidation.firstName,
        ),
        lastName: getFieldValidation(
          acc.lastName,
          passengerValidation.lastName,
        ),
        fullName: getFieldValidation(
          acc.fullName,
          passengerValidation.fullName,
        ),
        dateOfBirth: getFieldValidation(
          acc.dateOfBirth,
          passengerValidation.dateOfBirth,
        ),
      };
    },
    getDefaultPassengerValidation(t),
  );
};

export const parsePassengerTitles = (
  passengerTitles: Maybe<PassengerTitlesFragment[]>,
) => {
  if (!passengerTitles) {
    return {};
  }

  return passengerTitles.reduce<{ [key: string]: DropdownValue[] }>(
    (acc, record) => ({
      ...acc,
      [record.passengerType]: record.options.map(({ label, value }) => ({
        value,
        label: label.value || '',
      })),
    }),
    {},
  );
};

export const parsePassengerGenders = (
  passengerGenders: Maybe<PassengerGendersFragment[]>,
) => {
  if (!passengerGenders) {
    return {};
  }

  return passengerGenders.reduce<{ [key: string]: DropdownValue[] }>(
    (acc, record) => ({
      ...acc,
      [record.passengerType]: record.options.map(({ label, value }) => ({
        value,
        label: label.value || '',
      })),
    }),
    {},
  );
};

export const getFrequentFlyerProgramsDropdownValues = (vendors: Vendors) => {
  return getDropdownValue(
    vendors
      .map((v) => {
        return {
          name: v.vendorBookingConfig?.frequentFlyerConfig?.name,
          code: v.name,
        };
      })
      .filter(
        (v): v is { code: string; name: string } =>
          Boolean(v.code) && Boolean(v.name),
      ),
  );
};

export const expectedTypeToPassengerType = (expected_type: PaxType) => {
  const type = expected_type.toLowerCase();

  if (type === 'c') {
    return CMSPassengerType.CHILD;
  } else if (type === 'i') {
    return CMSPassengerType.INFANT;
  }

  return CMSPassengerType.ADULT;
};

export const paxTypeToExpectedType = (paxType: CMSPassengerType) => {
  if (paxType === CMSPassengerType.CHILD) {
    return PaxType['c'];
  } else if (paxType === CMSPassengerType.INFANT) {
    return PaxType['i'];
  }

  return PaxType['a'];
};

export const getYearOptions = (
  passenger: Passenger,
  vendorPassengerRules: PassengerRulesFragment | null,
  lastFlightDate?: string,
) => {
  const minAge = getPaxTypeMinAge(
    vendorPassengerRules,
    expectedTypeToPassengerType(passenger.expected_type),
  );

  const maxAge = getPaxTypeMaxAge(
    vendorPassengerRules,
    expectedTypeToPassengerType(passenger.expected_type),
  );

  const currentYear = dayjs(lastFlightDate).year();
  const startYear = maxAge ? currentYear - maxAge - 1 : 1900;
  const endYear = minAge ? currentYear - minAge : currentYear;

  const yearArray = generateNumberArray(startYear, endYear).slice().reverse();

  return getDropdownValue(
    yearArray.map((year) => ({
      name: String(year),
      code: String(year),
    })),
  );
};

export const getPassportExpiryYearOptions = () => {
  const startYear = dayjs().year();

  const yearArray = generateNumberArray(startYear, startYear + 11);

  return getDropdownValue(
    yearArray.map((year) => ({
      name: String(year),
      code: String(year),
    })),
  );
};

export const constructUpdatedPassengers = (
  passengers: Passenger[],
  passenger: Passenger,
) => {
  return passengers.map((pax) =>
    pax.passenger_id === passenger.passenger_id ? passenger : pax,
  );
};

export const getVendorPassengerRules = (vendors: Vendors) => {
  return Object.values(vendors)
    .filter((vendor) => vendor.vendorPassengersConfig)
    .reduce<PassengerRulesFragment | null>((acc, vendorPaxConfig) => {
      const currentAdultMinAge = acc?.rules.find(
        (rule) => rule.passengerType === CMSPassengerType.ADULT,
      )?.minAge;

      const newAdultMinAge = vendorPaxConfig.vendorPassengersConfig?.rules.find(
        (rule) => rule.passengerType === CMSPassengerType.ADULT,
      )?.minAge;

      if (
        (currentAdultMinAge &&
          newAdultMinAge &&
          newAdultMinAge > currentAdultMinAge) ||
        !acc
      ) {
        return {
          rules: vendorPaxConfig.vendorPassengersConfig?.rules,
          maxCount:
            vendorPaxConfig.vendorPassengersConfig?.maximumPassengersCount,
        } as PassengerRulesFragment;
      }

      return acc;
    }, null);
};

export const findGenderOfPax = (
  paxTitle?: string,
  passengerTitles?: PassengerTitlesWithPaxGender[],
) =>
  passengerTitles?.reduce<PaxGender | undefined>((gender, title) => {
    const option = title.options.find((option) => option.value === paxTitle);

    return gender || option?.gender;
  }, undefined);

export const mapGenderToTitle = (gender?: PaxGender) => {
  switch (gender) {
    case 'f':
      return 'ms';
    case 'm':
    case 'x':
      return 'mr';
    default:
      return '';
  }
};

export const filterOutPassportIfNotNeeded = (
  fields: PassengerFields[],
  passportRequired: boolean,
) => {
  return fields.filter((field) => {
    if (passportRequired) {
      return true;
    }

    return (
      field !== PassengerFields.PASSPORT_COUNTRY &&
      field !== PassengerFields.PASSPORT_EXPIRATION &&
      field !== PassengerFields.PASSPORT_NUMBER
    );
  });
};

export const checkIfUniqueNames = (
  passengers: Omit<Passenger, 'expected_type' | 'passenger_id'>[],
) => {
  const hasDuplicateFullnames = passengers.some((passenger) => {
    return (
      passengers.filter(
        (p) =>
          p.first_name === passenger.first_name &&
          p.last_name === passenger.last_name &&
          p.first_name !== '' &&
          p.last_name !== '',
      ).length > 1
    );
  });

  return !hasDuplicateFullnames;
};

export const checkIfAgeIsValid = ({
  dateOfBirth,
  lastFlightDate,
  t,
  type,
  vendorPassengerRules,
}: {
  dateOfBirth?: string;
  lastFlightDate?: string;
  t: TranslateCmsString;
  type?: PaxType;
  vendorPassengerRules: PassengerRulesFragment;
}) => {
  const birth = dayjs(dateOfBirth);

  const ageWhenLastFlightDeparts = dayjs(lastFlightDate).diff(birth, 'year');

  if (typeof ageWhenLastFlightDeparts === 'undefined' || !type) {
    return { errorMessage: '', isInvalidAge: false };
  }

  const adultMinAge = getPaxTypeMinAge(
    vendorPassengerRules,
    expectedTypeToPassengerType(PaxType['a']),
  );

  const childrenMinAge = getPaxTypeMinAge(
    vendorPassengerRules,
    expectedTypeToPassengerType(PaxType['c']),
  );

  const childrenMaxAge =
    getPaxTypeMaxAge(
      vendorPassengerRules,
      expectedTypeToPassengerType(PaxType['c']),
    ) ?? 15;

  const infantMaxAge =
    getPaxTypeMaxAge(
      vendorPassengerRules,
      expectedTypeToPassengerType(PaxType['i']),
    ) ?? 1;

  const validationConditions = {
    [PaxType['a']]: () => ageWhenLastFlightDeparts < adultMinAge,
    [PaxType['c']]: () =>
      ageWhenLastFlightDeparts < childrenMinAge ||
      ageWhenLastFlightDeparts > childrenMaxAge,
    [PaxType['i']]: () => ageWhenLastFlightDeparts > infantMaxAge,
  };

  const errorMessages = {
    [PaxType['a']]: 'Adults_must_be_older_than_{{adultMinAge}}',
    [PaxType['c']]:
      'Children_must_be_between_{{childrenMinAge}}_and_{{childrenMaxAge}}',
    [PaxType['i']]: 'Infants_must_be_younger_than_{{infantMaxAge}}',
  };

  const validationParams = {
    [PaxType['a']]: { adultMinAge },
    [PaxType['c']]: { childrenMinAge, childrenMaxAge },
    [PaxType['i']]: { infantMaxAge: infantMaxAge + 1 },
  };

  const validationError = validationConditions[type]();

  if (validationError) {
    return {
      errorMessage: t(
        errorMessages[type],
        errorMessages[type],
        validationParams[type],
      ),
      isInvalidAge: true,
    };
  }

  return { errorMessage: '', isInvalidAge: false };
};

export const getLastFlightDate = (legs: Leg[]) =>
  legs.reduce((latestDate, leg) => {
    return dayjs(leg.departure).isAfter(latestDate)
      ? leg.departure
      : latestDate;
  }, legs[0]?.departure);

export const getAdultAndChildrenString = (
  passengers: Passenger[],
  t: TranslateCmsString,
) => {
  const numberOfAdults = passengers.filter(
    (pax) => pax.expected_type === PaxType['a'],
  ).length;

  const numberOfChildren = passengers.filter(
    (pax) => pax.expected_type === PaxType['c'],
  ).length;

  let paxString = numberOfAdults ? `${numberOfAdults} ` : '';
  paxString +=
    numberOfAdults && numberOfAdults > 1
      ? t('Adults', 'Adults')
      : t('a', 'Adult');

  if (numberOfChildren && numberOfChildren > 0) {
    paxString += `, ${numberOfChildren} `;
    paxString +=
      numberOfChildren > 1 ? t('Children', 'Children') : t('c', 'Child');
  }

  return paxString;
};

export const getPassengerTitle = ({
  passengerType,
  quantity,
  t,
}: {
  passengerType?: PaxType;
  quantity: number;
  t: TranslateCmsString;
}) => {
  if (passengerType === PaxType['a']) {
    return quantity > 1 ? t('Adults', 'Adults') : t('a', 'Adult');
  }

  if (passengerType === PaxType['c']) {
    return quantity > 1 ? t('Children', 'Children') : t('c', 'Child');
  }

  return quantity > 1 ? t('Infants', 'Infants') : t('i', 'Infant');
};
