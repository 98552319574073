import Link, { LinkProps } from 'next/link';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Button, { ButtonProps } from './Button';

export type LinkButtonProps = Omit<ButtonProps, 'ref'> & {
  href: string;
  isSsg?: boolean;
  shallow?: LinkProps['shallow'];
  target?: React.HTMLAttributeAnchorTarget;
} & LinkProps;

const StyledLink = styled(Link)<{ width: ButtonProps['width'] }>(
  ({ width }) => [
    css`
      display: inline-block;
      text-decoration: none;
    `,
    width === 'full' &&
      css`
        width: 100%;
      `,
  ],
);

const LinkButton = ({
  href,
  isSsg = true,
  shallow,
  target,
  width,
  ...buttonProps
}: LinkButtonProps) => {
  return (
    <StyledLink
      href={href}
      isSsg={isSsg}
      shallow={shallow}
      target={target}
      width={width}
    >
      <Button {...buttonProps} width={width} />
    </StyledLink>
  );
};

export default LinkButton;
