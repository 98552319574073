import {
  useGetPassengersConfigQuery,
  PassengerRulesFragment,
} from '@codegen/cmsUtils';
import { passengerTitlesWithGenderType } from '@web/utils/partnerUtils';
import { useConstants } from '../ConstantContext';

const usePartnerPassengersConfig = () => {
  const { locale, partner } = useConstants();

  const { data } = useGetPassengersConfigQuery(
    {
      partner,
      locale,
    },
    { retry: 2 },
  );

  if (!data?.partner?.passengersConfig?.passengerRules) {
    throw Error(`Critical error - Unable to fetch partner: ${partner}`);
  }

  const { partner: partnerData } = data;

  return {
    passengerRules: partnerData.passengersConfig
      ?.passengerRules as PassengerRulesFragment,
    passengerTitles: passengerTitlesWithGenderType(
      partnerData.passengersConfig?.passengerTitles || [],
    ),
    passengerGenders: partnerData.passengersConfig?.passengerGenders,
  };
};

export default usePartnerPassengersConfig;
