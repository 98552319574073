import { useState } from 'react';
import Box from '@ui-v2/core/Box/Box';
import Button from '@ui-v2/core/Button/Button';
import GridColumn from '@ui-v2/core/Grid/GridColumn';
import GridRow from '@ui-v2/core/Grid/GridRow';
import Image from '@ui-v2/core/Image/Image';
import Modal from '@ui-v2/core/Modal/Modal';
import Text from '@ui-v2/core/Text/Text';
import useCmsTranslation from '@utils/hooks/useCmsTranslation';
import useGetIllustration from '@utils/hooks/useGetIllustration';
import useTimeout from '@utils/hooks/useTimeout';
import useGetOffer from '@web/context/hooks/useGetOffer';
import ClientExperimentWrapper from '../../ClientExperimentWrapper';
import { useModalOrchestrator } from '../../ModalOrchestratorContext';

type Props = {
  isOpen: boolean;
};

const BookingLoadingModal = ({ isOpen }: Props) => {
  const { t } = useCmsTranslation();

  const { hideModal } = useModalOrchestrator();
  const { isOfferLoading } = useGetOffer();
  const [timeoutStatus, setTimeoutStatus] = useState<'not-expired' | 'expired'>(
    isOfferLoading ? 'not-expired' : 'expired',
  );

  const selfConnect = useGetIllustration()('selfConnect');

  const isLoading = isOfferLoading || timeoutStatus === 'not-expired';

  useTimeout(() => {
    setTimeoutStatus('expired');
  }, 3000);

  return (
    <ClientExperimentWrapper>
      <Modal
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={false}
        id="first-refresh-loading-modal"
        isOpen={isOpen}
        maxWidth={710}
        onOpenChange={(isOpen) => (!isOpen ? hideModal() : null)}
        showCloseButton={false}
      >
        <Box pb={32} pt={[24, 24, 56]} px={[0, 24]}>
          <GridRow>
            <GridColumn order={[2, 1]} width={['full', '7/12']}>
              <Box
                display="flex"
                flexDirection="column"
                height="100%"
                justifyContent="center"
              >
                <Text as="h2" mb={16} variant="heading-2">
                  {t(
                    'first_refresh_loading_modal_title',
                    'Connect with confidence',
                  )}
                </Text>
                <Text as="div">
                  {t(
                    'first_refresh_loading_modal_description',
                    `Dohop connection service is always included to get you where you're going`,
                  )}
                </Text>
              </Box>
            </GridColumn>
            <GridColumn order={[1, 2]} width={['full', '5/12']}>
              <Box display="flex" justifyContent={['center', 'flex-end']}>
                {selfConnect?.asset.url && (
                  <Image height={200} src={selfConnect.asset.url} width={230} />
                )}
              </Box>
            </GridColumn>
          </GridRow>
        </Box>
        <Box display="flex" justifyContent="space-between" pt={16}>
          <Button onClick={() => hideModal()} size="small" variant="tertiary">
            {t('Close', 'Close')}
          </Button>

          <Button
            isLoading={isLoading}
            onClick={() => hideModal()}
            size="small"
          >
            {t(
              'first_refresh_loading_modal_confirm_button',
              'Continue to booking',
            )}
          </Button>
        </Box>
      </Modal>
    </ClientExperimentWrapper>
  );
};

export default BookingLoadingModal;
