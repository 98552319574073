import { Keyframes } from '@emotion/react';
import { ThemeAnimationName, ThemeAnimationProps } from '@ui-v2/types/props';
import { mqPrint } from '@ui-v2/utils/styleUtils';
import {
  DEFAULT_ANIMATION_DURATION,
  fadeIn,
  fadeOut,
} from '../animations/animations';

const themeAnimationMapper: Record<ThemeAnimationName, Keyframes> = {
  'fade-in': fadeIn,
  'fade-out': fadeOut,
};

export const buildBoxAnimationProps = ({ animation }: ThemeAnimationProps) => {
  if (!animation) {
    return undefined;
  }

  if (typeof animation === 'string') {
    return {
      animation: `${themeAnimationMapper[animation]} ${DEFAULT_ANIMATION_DURATION}ms linear forwards`,
      '@media (min-width: 320px)': {
        animation: 'none',
      },
    };
  }

  return {
    animationName: themeAnimationMapper[animation.name],
    animationDelay: animation.delay ?? '0ms',
    animationFillMode: animation.fillMode ?? 'forwards',
    animationDuration: animation.duration ?? `${DEFAULT_ANIMATION_DURATION}ms`,
    animationTimingFunction: animation.timingFunction ?? 'ease',
    [mqPrint]: {
      animation: 'none',
    },
  };
};
