import { useCallback, useEffect, useMemo, useState } from 'react';
import { RUNTIME_ENV } from '@shared/types/enums';
import useEfffectOnce from '@ui/hooks/useEffectOnce';
import { pageview } from '@web/utils/analytics/Avo';
import { useConstants } from '../ConstantContext';
import { useExperiments } from '../ExperimentContext';
import { useSettings } from '../SettingsContext';
import { addUserProperties } from '../utils/analyticsContextUtils';
import usePartnerRouter from './usePartnerRouter';

const useAnalyticsQueue = () => {
  const { route } = usePartnerRouter();
  const { locale } = useConstants();
  const { currency, residency } = useSettings();
  const { experiments } = useExperiments();
  const { partner } = useConstants();

  const [hasRudderStackBeenInitialized, setHasRudderStackBeenInitialized] =
    useState(
      // We default to true on non prod environments since cookie consent and tracking is only on prod
      process.env.NEXT_PUBLIC_RUNTIME_ENV !== RUNTIME_ENV.production,
    );

  const pageName = useMemo(() => route.substring(1), [route]);

  const [, setAnalyticsQueue] = useState<(() => void)[]>([
    addUserProperties({
      experiments,
      partner,
      locale,
      currency,
      residency,
    }),
  ]);

  const processAnalyticsQueue = useCallback(() => {
    setAnalyticsQueue((prevQueue) => {
      prevQueue.forEach((analyticsEvent) => {
        analyticsEvent();
      });

      return [];
    });
  }, []);

  const pushToAnalyticsQueue = useCallback(
    (analyticsEvent: () => void) => {
      // If the user has not interacted to the cookie consent, we need to wait until they have
      if (!hasRudderStackBeenInitialized) {
        setAnalyticsQueue((prevQueue) => [...prevQueue, analyticsEvent]);
      } else {
        // User has consented, firing all events
        setAnalyticsQueue((prevQueue) => {
          prevQueue.forEach((analyticsEvent) => {
            analyticsEvent();
          });
          analyticsEvent();

          return [];
        });
      }
    },
    [hasRudderStackBeenInitialized],
  );

  useEfffectOnce(() => {
    const initializeAnalyticsQueue = () => {
      // If the user has interacted with the cookie consent, we should process the analytics queue
      setHasRudderStackBeenInitialized(true);

      processAnalyticsQueue();
    };

    window.addEventListener('rudderstackInitialized', initializeAnalyticsQueue);

    return () =>
      window.removeEventListener(
        'rudderstackInitialized',
        initializeAnalyticsQueue,
      );
  });

  useEffect(() => {
    const handleSuccesfullRouteChange = () => {
      pushToAnalyticsQueue(() => {
        pageview({
          customDestinationPageName_: pageName,
          path: route,
          search: window.location.search,
          pageTitle: document.title,
          referrer: document.referrer,
          pageName,
        });
      });
    };

    handleSuccesfullRouteChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  return { pushToAnalyticsQueue, processAnalyticsQueue };
};

export default useAnalyticsQueue;
