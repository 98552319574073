import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import { hexToCSSFilter } from 'hex-to-css-filter';
import { isHex } from '@ui-v2/utils/styleUtils';

export const clampLines = (numberOfLines: number) => css`
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${numberOfLines};
  visibility: visible;
`;

export const centerVertically = css`
  display: flex;
  align-items: center;
`;

export const centerHorizontally = css`
  display: flex;
  justify-content: center;
`;

export const centerContent = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const loadingAnimation = (diameter: number, color = '#fff') => css`
  position: relative;
  color: transparent;

  &[aria-disabled='true'],
  &[aria-disabled='true']:hover,
  &[aria-disabled='true']:focus {
    color: transparent;
  }

  &::after {
    position: absolute;
    z-index: 2;
    top: calc(50% - ${diameter / 2}px);
    left: calc(50% - ${diameter / 2}px);
    width: ${diameter}px;
    height: ${diameter}px;
    border: 2px solid transparent;
    border-radius: 50%;
    border-right-color: ${color};
    animation: loading-anim 1.5s linear infinite;
    content: '';
  }

  @keyframes loading-anim {
    from {
      transform: rotate(0);
    }

    to {
      transform: rotate(360deg);
    }
  }
`;

/**
 * Note: The hexToCSSFilter library seems to have
 * limited SSR support. The values generated differ between
 * server and client and results in a class name mismatch.
 */
export const createCSSFilterFromHex = (color: string) =>
  isHex(color) &&
  css`
    color-interpolation-filters: srgb;
    filter: ${hexToCSSFilter(color).filter};
  `;

export const setCursorOnHover = css`
  &:hover {
    cursor: pointer;
  }
`;

export const shouldForwardProp = ({
  excludeProps,
  prop,
}: {
  excludeProps?: string[];
  prop: string;
}) => isPropValid(prop) && !excludeProps?.includes(prop);

export const resetList = css`
  padding: 0;
  margin: 0;
  list-style: none;
`;
