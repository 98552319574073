import * as Sentry from '@sentry/nextjs';
import { DohopConnectQuery } from '@codegen/cmsUtils';

export const CURRENT_DATOCMS_ENV =
  process.env['NEXT_PUBLIC_DATO_CMS_ENVIRONMENT'];

export const GATEWAY_URL =
  process.env['NEXT_PUBLIC_GATEWAY_URL'] ||
  'https://gateway.staging.dohop.net/api/graphql';

export const silentErrorLogger = (error: unknown) =>
  Sentry.captureException(error);

export const defaultReactQueryClientOptions = {
  queries: {
    staleTime: Infinity,
    onError: silentErrorLogger,
    refetchOnWindowFocus: false,
  },
};

export const parseQueryString = (query?: string | string[] | null) => {
  if (!query) {
    return '';
  }

  if (typeof query === 'string' && query !== 'UNDEFINED') {
    return query;
  }

  return query[0] ?? '';
};

export const parseUtmSource = (utmSource?: string | string[] | null) => {
  if (!utmSource) {
    return '';
  }

  if (typeof utmSource === 'string' && utmSource !== 'UNDEFINED') {
    return utmSource;
  }

  if (Array.isArray(utmSource)) {
    return utmSource.join(',');
  }

  return '';
};

export const parseQueryStringToObject = (queryString?: string) => {
  if (!queryString) {
    return {};
  }

  const searchParams = new URLSearchParams(queryString);

  return Array.from(searchParams).reduce<Record<string, string>>(
    (acc, [key, value]) => ({ ...acc, [key]: value }),
    {},
  );
};

export const dohopConnectQueryPlaceholderData: DohopConnectQuery = {
  dohopConnect: {
    name: 'Dohop Connect',
    supportLinks: [],
    termsLinks: [],
    partialBookingLinks: [],
    exitRowTermsLinks: [],
    logo: {
      url: '',
      id: '',
    },
    iconConfig: null,
    illustrationConfig: {
      dohopConnectIllustration: null,
    },
    supportEmail: '',
    richModalTitle: {
      id: '',
      stringId: '',
      __typename: 'ApplicationStringRecord',
      value: '',
    },
    richModalBulletpoints: [],
    richSectionTitle: {
      id: '',
      stringId: '',
      __typename: 'ApplicationStringRecord',
      value: '',
    },
    richSectionItems: [],
    richSectionTitleVis: {
      id: '',
      stringId: '',
      __typename: 'ApplicationStringRecord',
      value: '',
    },
    richSectionSemiTitleVis: {
      id: '',
      stringId: '',
      __typename: 'ApplicationStringRecord',
      value: '',
    },
    richSectionItemsVis: [],
  },
};
