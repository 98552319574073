import { createContext, ReactNode, useContext } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useRouter } from 'next/router';
import { Partner, Language } from '@shared/types/enums';

export type Constants = {
  locale: Language;
  partner: Partner;
};

const context = createContext<Constants>({
  partner: Partner.Dohop,
  locale: Language.English,
});

export const ConstantProvider = ({
  children,
  partner,
}: {
  children: ReactNode;
  partner: Partner;
}) => {
  const { locale } = useRouter();

  return (
    <context.Provider value={{ partner, locale: locale as Language }}>
      {children}
    </context.Provider>
  );
};

export const useConstants = () => useContext(context);
