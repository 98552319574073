export const i18nConfig = {
  locales: [
    'default',
    'en',
    'de',
    'pt',
    'nl',
    'it',
    'fr',
    'es',
    'ko',
    'ja',
    'th',
    'zh',
    'no',
    'el',
  ],
  defaultLocale: 'default',
  loader: false,
  defaultNS: 'common',
  keySeparator: false,
  nsSeparator: false,
  pages: {
    '/': ['common'],
  },
  logger: () => {},
  localeDetection: false,
};
