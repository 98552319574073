import { QueryClient } from '@tanstack/react-query';
import { useGetPartnerQuery } from '@codegen/cmsUtils';
import { Partner } from '@shared/types/enums';

export const prefetchPartnerConfig = async ({
  partner,
  queryClient,
}: {
  partner: Partner;
  queryClient: QueryClient;
}) => {
  return await queryClient.fetchQuery(
    useGetPartnerQuery.getKey({ partner }),
    async () => await useGetPartnerQuery.fetcher({ partner })(),
    { retry: 2 },
  );
};
