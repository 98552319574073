import { ThemeV4Fragment } from '@codegen/cmsUtils';
import { buildBorderVariants } from './borders';
import { buildColours } from './colours';
import { sizes, spacings } from './layout';
import { buildShapes } from './shapes';
import { zIndices } from './zIndices';

export const constructTheme = (theme: ThemeV4Fragment) => {
  return {
    zIndices,
    sizes,
    spacings,
    colours: buildColours(theme),
    borders: buildBorderVariants(theme),
    shape: buildShapes(theme),
    shadows: theme.boxShadow,
    typography: theme.typography,
  };
};

export type ThemeInterface = ReturnType<typeof constructTheme>;

declare module '@emotion/react' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface Theme extends ThemeInterface {}
}
