import { AriaRole } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { DropdownValue } from '@shared/types/dropdownTypes';
import Text from '@ui-v2/core/Text/Text';
import { createTypography, mqMin } from '@ui-v2/utils/styleUtils';
import { ReactComponent as TinyDownArrowIcon } from '../../assets/icons/tiny-down-arrow.svg';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { InputContainer } from '../Input/InputContainer';

export interface Props {
  className?: string;
  errorMessage?: string;
  id: string;
  isActive?: boolean;
  label?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  role?: AriaRole;
  showError?: boolean;
  value?: string;
  values: { label?: string; values: DropdownValue[] }[];
}

export const StyledTinyDownArrowIcon = styled(TinyDownArrowIcon)(
  ({ theme: { colours, spacings } }) => css`
    position: absolute;
    right: ${spacings['16']}px;
    width: 10px;
    height: 10px;
    fill: ${colours.brand.primary};
    pointer-events: none;
  `,
);

const Container = styled.div(({ theme: { colours, typography } }) => [
  createTypography(typography.body01),
  css`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    color: ${colours.text.default};
  `,
]);

export const Select = styled.select(
  ({ theme: { spacings } }) => css`
    width: 100%;
    height: 40px;
    padding: 0 0 0 ${spacings['8']}px;
    border: none;
    appearance: none;
    cursor: pointer;
    outline: none;

    ${mqMin[768]} {
      height: 50px;
    }

    &:hover,
    &:active,
    &:focus,
    &:focus-visible {
      border: none;
      appearance: none;
      cursor: pointer;
      outline: none;
    }
  `,
);

const Dropdown = ({
  className,
  errorMessage,
  id,
  isActive = false,
  label,
  onChange,
  placeholder,
  role,
  showError = false,
  value,
  values,
}: Props) => {
  const hasError = Boolean(errorMessage);
  const useOptGroups = values.length > 1;

  const renderOptions = (options: DropdownValue[] = []) =>
    options.map((v) => (
      <option key={v.value} value={v.value}>
        {v.label}
      </option>
    ));

  return (
    <Container role={role}>
      {label && (
        <Text as="label" htmlFor={id} mb={4} variant="heading-7">
          {label}
        </Text>
      )}
      <InputContainer
        className={className}
        hasError={hasError}
        isActive={isActive}
      >
        <Select
          aria-label={id}
          id={id}
          onChange={(e) => onChange && onChange(e.target.value)}
          value={value}
        >
          {placeholder && (
            <option disabled selected>
              {placeholder}
            </option>
          )}
          {useOptGroups
            ? values
                .filter((valueGroup) => valueGroup.values.length > 0)
                .map((optionGroup) => (
                  <optgroup key={optionGroup.label} label={optionGroup.label}>
                    {renderOptions(optionGroup.values)}
                  </optgroup>
                ))
            : renderOptions(values[0]?.values)}
        </Select>
        <StyledTinyDownArrowIcon />
      </InputContainer>
      {showError && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Container>
  );
};

export default Dropdown;
