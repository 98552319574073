import dayjs, { Dayjs } from 'dayjs';
import { Language } from '@shared/types/enums';

export const SECONDS_IN_MINUTE = 60;

export const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * 60;

export const SECONDS_IN_DAY = SECONDS_IN_HOUR * 24;

const getDaysInMonth = (year: number, month: number) => {
  return new Date(year, month + 1, 0).getDate();
};

export const getUTCDaysBetween = (day1: dayjs.Dayjs, day2: dayjs.Dayjs) => {
  const day1Date = day1.toDate();
  const day2Date = day2.toDate();

  if (
    day1Date.getUTCFullYear() > day2Date.getUTCFullYear() &&
    day1Date.getUTCMonth() < day2Date.getUTCMonth()
  ) {
    return (
      getDaysInMonth(day2Date.getUTCFullYear(), day2Date.getUTCMonth()) +
      day1Date.getUTCDate() -
      day2Date.getUTCDate()
    );
  }

  if (day1Date.getUTCMonth() !== day2Date.getUTCMonth()) {
    return day1Date.getUTCMonth() > day2Date.getUTCMonth() &&
      day1Date.getUTCFullYear() >= day2Date.getUTCFullYear()
      ? getDaysInMonth(day2Date.getUTCFullYear(), day2Date.getUTCMonth()) +
          day1Date.getUTCDate() -
          day2Date.getUTCDate()
      : getDaysInMonth(day1Date.getUTCFullYear(), day1Date.getUTCMonth()) +
          day2Date.getUTCDate() -
          day1Date.getUTCDate();
  }

  return Math.abs(day2Date.getUTCDate() - day1Date.getUTCDate());
};

export const isSameDay = (
  day1?: dayjs.Dayjs | Date | null | string,
  day2?: dayjs.Dayjs | Date | null | string,
) => dayjs(day1).isSame(day2, 'day');

export const isDateInPast = (day?: Dayjs | Date | string) =>
  dayjs(day).isBefore(dayjs(), 'day');

export const isDateInFuture = (day?: Dayjs | Date | string | null) =>
  dayjs(day).isAfter(dayjs(), 'day');

export const getTimeStringFromDayjs = (
  date: Dayjs,
  locale: Language = Language.English,
): string => {
  return date.toDate().toLocaleTimeString(locale, {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'UTC',
    hourCycle: 'h23',
  });
};

// Returns time of day in minutes
export const getMinutesPastMidnight = (date: Dayjs): number => {
  const midnight = dayjs(date).set('hour', 0).set('minute', 0);

  return date.diff(midnight, 'minutes');
};

export const parseDateString = (dateString: string) => {
  return dayjs(dateString);
};

export const toUTCLocaleString = ({
  date,
  locale = Language.English,
  options = {},
  residency = 'UK',
}: {
  date: Date;
  locale?: Language | string;
  options?: Omit<Intl.DateTimeFormatOptions, 'timeZone'>;
  residency: string;
  // eslint-disable-next-line no-restricted-syntax
}) => {
  const correctLocale = getCorrectLocale(locale, residency);

  // eslint-disable-next-line no-restricted-syntax
  return date.toLocaleString(correctLocale, { ...options, timeZone: 'UTC' });
};

export const getCorrectLocale = (
  locale: Language | string,
  residency: string,
) => {
  // these countries exclusively use MM/DD/YYYY according to Wikipedia
  const weirdDateFormatCountries = [
    'US', // United States
    'FM', // Federated states of Micronesia
    'GM', // Guam
    'MH', // Marshall Islands
    'MP', // Northern Mariana Islands
    'VI', // Virgin Islands
    'AS', // American Samoa
  ];

  const res = residency.toUpperCase();

  if (locale === Language.English) {
    if (weirdDateFormatCountries.find((country) => country === res)) {
      return 'en-US';
    }

    return 'en-GB';
  }

  return locale;
};

export const getDuration = (seconds: number) => {
  const days =
    seconds >= SECONDS_IN_DAY ? `${Math.floor(seconds / SECONDS_IN_DAY)}` : '';

  const hours = `${Math.floor((seconds % SECONDS_IN_DAY) / SECONDS_IN_HOUR)}`;

  const minutes = (
    '0' + Math.floor((seconds % SECONDS_IN_HOUR) / SECONDS_IN_MINUTE)
  ).slice(-2);

  return [days, hours, minutes];
};

export const isSameOrAfter = (
  dayOne: dayjs.Dayjs | Date | string,
  dayTwo: dayjs.Dayjs | Date | string,
) => {
  return dayjs(dayOne).isSame(dayTwo) || dayjs(dayOne).isAfter(dayTwo);
};
