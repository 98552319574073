import { Theme, css } from '@emotion/react';
import { FieldPath } from 'react-hook-form';
import { z } from 'zod';
import { CurrencyCode } from '@codegen/gatewayUtils';
import { TokenizedCardDetails } from '@codegen/offerAPI';
import {
  CardTypes,
  SecureFieldsOptions,
} from '@shared/types/secureFieldsTypes';
import { UpliftOrderInfo } from '@shared/types/upliftTypes';
import { TranslateCmsString } from '@utils/hooks/useCmsTranslation';
import { getZodExpiryDateObject } from '@web/utils/booking/zodUtils';

export enum PaymentMethod {
  ApplePay = 'ApplePay',
  PCIProxyCreditCard = 'PCIProxyCreditCard',
  Uplift = 'Uplift',
}

export const getZodPCIProxyCreditCard = (t: TranslateCmsString) =>
  z.object({
    paymentMethod: z.literal(PaymentMethod.PCIProxyCreditCard),
    expiryDate: getZodExpiryDateObject(
      t,
      t('Please enter a card expiry date', 'Please enter a card expiry date'),
    ),
    name: z
      .string({
        required_error: t(
          'Please enter the name on your card',
          'Please enter the name on your card',
        ),
      })
      .min(
        1,
        t(
          'Please enter the name on your card',
          'Please enter the name on your card',
        ),
      ),
    cvc: z
      .string({
        required_error: t(
          'Please enter a valid CVC/CVV number',
          'Please enter a valid CVC/CVV number',
        ),
      })
      .min(
        1,
        t(
          'Please enter a valid CVC/CVV number',
          'Please enter a valid CVC/CVV number',
        ),
      ),
    cardNumber: z
      .string({
        required_error: t(
          'Please enter a valid card number',
          'Please enter a valid card number',
        ),
      })
      .min(
        1,
        t(
          'Please enter a valid card number',
          'Please enter a valid card number',
        ),
      ),
    cardType: z.nativeEnum(CardTypes).optional(),
  });

export const getZodUplift = () =>
  z.object({
    paymentMethod: z.literal(PaymentMethod.Uplift),
    upliftAccepted: z.boolean().refine((val) => val === true),
  });

export const getZodApplePay = () =>
  z.object({
    paymentMethod: z.literal(PaymentMethod.ApplePay),
  });

export const getZodPaymentObject = (t: TranslateCmsString) =>
  z.discriminatedUnion('paymentMethod', [
    getZodPCIProxyCreditCard(t),
    getZodUplift(),
    getZodApplePay(),
  ]);

export const getSecureFieldsOptions = ({ colours, typography }: Theme) => {
  const initialInputStyle = css`
    padding: 0;
    border-radius: 0;
    margin: 0;
    appearance: none;
  `;

  return {
    styles: {
      cardNumber: `${initialInputStyle}`,
      cvv: `${initialInputStyle}`,
      '*::placeholder': `color: ${colours.text.default};`,
      '*': {
        fontFamily: 'Inter, sans-serif',
        fontSize: `${typography.body01.fontSize}px`,
        fontWeight: typography.body01.fontWeight,
        color: colours.text.default,
      },
      '@font-face': { '*': 'Inter' },
    },
  };
};

export const getPaymentMethodFromString = (paymentMethod: string) => {
  if (paymentMethod === PaymentMethod.PCIProxyCreditCard) {
    return PaymentMethod.PCIProxyCreditCard;
  }

  if (paymentMethod === PaymentMethod.Uplift) {
    return PaymentMethod.Uplift;
  }
};

export type ZodPCIProxyCreditCard = z.infer<
  ReturnType<typeof getZodPCIProxyCreditCard>
>;

export type ZodPayment = z.infer<ReturnType<typeof getZodPaymentObject>>;

export type PaymentFieldName = FieldPath<ZodPayment>;

type PCIProxyCreditCardOnPaymentSubmit = (data: {
  cardType: string;
  expiryDate: string;
  nameOnCard: string;
  paymentMethod: PaymentMethod.PCIProxyCreditCard;
  transactionId: string;
}) => void;

type UpliftOnPaymentSubmit = (data: {
  cardType: string;
  expiryDate: string;
  nameOnCard: string;
  paymentMethod: PaymentMethod.Uplift;
  tokenizedCardDetails: TokenizedCardDetails;
}) => void;

type ApplePayPaymentSubmit = (data: {
  applePayToken: string;
  onError: () => void;
  onSuccess: () => void;
  paymentMethod: PaymentMethod.ApplePay;
}) => void;

export type PCIProxyConfig = {
  excludedCardTypes: CardTypes[];
  onPaymentSubmit: PCIProxyCreditCardOnPaymentSubmit;
  options?: SecureFieldsOptions;
};

export type UpliftConfig = {
  currency: CurrencyCode;
  onPaymentSubmit: UpliftOnPaymentSubmit;
  orderInfo: UpliftOrderInfo;
};

export type ApplePayConfig = {
  applePayData: ApplePayJS.ApplePayPaymentRequest;
  onCancel: ApplePaySession['oncancel'];
  onPaymentSubmit: ApplePayPaymentSubmit;
  onValidateMerchant: ({
    onSuccess,
  }: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess: (data: any) => void;
  }) => void;
};

export type PaymentMethodsConfig = {
  [PaymentMethod.PCIProxyCreditCard]: PCIProxyConfig;
  [PaymentMethod.Uplift]: UpliftConfig;
  [PaymentMethod.ApplePay]: ApplePayConfig;
};
