import { useEffect, useState } from 'react';
import { KeyboardKey } from '@shared/types/enums';
import Box from '../Box/Box';

const GridOverlay = () => {
  const [visible, toggleVisible] = useState(false);

  useEffect(() => {
    const keyHandler = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === KeyboardKey.Escape) {
        toggleVisible(!visible);
      }
    };

    document.addEventListener('keyup', keyHandler);

    return () => {
      document.removeEventListener('keyup', keyHandler);
    };
  }, [visible, toggleVisible]);

  if (!visible) {
    return null;
  }

  return (
    <Box height="100vh" position="fixed" top="0" width="100%" zIndex="modal">
      <Box
        display="flex"
        gap={[16, 16, 16, 24]}
        id="rugl"
        mx="auto"
        width="container"
      >
        {[...Array(12).keys()].map((i) => (
          <Box
            bg="brand.primary"
            height="100vh"
            key={i.toString()}
            opacity={0.2}
            width="100%"
          />
        ))}
      </Box>
    </Box>
  );
};

export default GridOverlay;
