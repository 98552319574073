import { css } from '@emotion/react';

const easyjetFonts = css`
  @font-face {
    font-display: fallback;
    font-family: 'easyJet Rounded Book';
    font-style: normal;
    font-weight: normal;
    src: url('/fonts/easyjet-rounded-book.woff2') format('woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: 'easyJet Headline';
    font-style: normal;
    font-weight: normal;
    src: url('/fonts/easyjet-generation-headline.woff2') format('woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: easyjet-generation-headline;
    font-style: normal;
    font-weight: normal;
    src: url('/fonts/easyjet-generation-headline.woff2') format('woff2');
  }

  @font-face {
    font-display: fallback;
    font-family: 'easyJet Rounded Demi';
    font-style: normal;
    font-weight: normal;
    src: url('/fonts/easyjet_rounded_demi-webfont.woff2') format('woff2');
  }
`;

export default easyjetFonts;
