import { Fragment } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Box from '@ui-v2/core/Box/Box';
import Container from '@ui-v2/core/Container/Container';
import Link from '@ui-v2/core/Link/Link';
import LinkAsButton from '@ui-v2/core/Link/LinkAsButton';
import { hideDuringPrint } from '@ui-v2/utils/styleUtils';
import useCmsTranslation from '@utils/hooks/useCmsTranslation';
import usePartnerFooter from '@web/context/hooks/usePartnerFooter';
import usePartnerInfo from '@web/context/hooks/usePartnerInfo';
import FooterItemContent from './FooterItemContent';

const StyledFooter = styled(Box)(({ theme }) => [
  hideDuringPrint,
  css`
    background-color: ${theme.colours.components.footer.surface};
    color: ${theme.colours.components.footer.text};
  `,
]);

const StyledLink = styled(Link)(
  ({ theme }) => css`
    color: ${theme.colours.components.footer.text};

    &::after {
      background-color: ${theme.colours.components.footer.text};
    }
  `,
);

const StyledLinkAsButton = styled(LinkAsButton)(
  ({ theme }) => css`
    color: ${theme.colours.components.footer.text};

    &::after {
      background-color: ${theme.colours.components.footer.text};
    }
  `,
);

const Footer = () => {
  const { t } = useCmsTranslation();
  const footerData = usePartnerFooter();
  const { trackingConfig } = usePartnerInfo();

  if (!footerData?.footerItems && !trackingConfig?.usercentricsId) {
    return null;
  }

  return (
    <StyledFooter bg="surface.main">
      <Container>
        <Box
          alignItems={['center', 'center', 'center', 'baseline']}
          display="flex"
          flexDirection={['column', 'column', 'column', 'row']}
          flexWrap="wrap"
          gap={16}
          justifyContent="space-around"
          py={16}
          width="100%"
        >
          {footerData?.footerItems.map((item) => (
            <Fragment key={item.id}>
              {item.link && (
                <StyledLink
                  href={item.link.href}
                  size="regular"
                  target={item.link.target ?? '__blank'}
                  variant="tertiary"
                >
                  <FooterItemContent
                    image={item.image}
                    label={item.label?.value ?? item.link.label.value}
                  />
                </StyledLink>
              )}
              {!item.link && item.image && (
                <FooterItemContent
                  image={item.image}
                  label={item.label?.value}
                />
              )}
            </Fragment>
          ))}
          {trackingConfig?.usercentricsId && (
            <StyledLinkAsButton
              onClick={() => window.UC_UI?.showSecondLayer()}
              size="regular"
              variant="tertiary"
            >
              {t('Privacy Settings', 'Privacy Settings')}
            </StyledLinkAsButton>
          )}
        </Box>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
