import { useGetHeaderConfigQuery } from '@codegen/cmsUtils';
import { useConstants } from '../ConstantContext';
import { useExperiments } from '../ExperimentContext';

const usePartnerHeader = () => {
  const { locale, partner } = useConstants();
  const { experimentData } = useExperiments();
  const { data } = useGetHeaderConfigQuery(
    { partner, locale },
    { keepPreviousData: true },
  );

  return experimentData?.headerConfig || data?.partner?.headerConfig;
};

export default usePartnerHeader;
