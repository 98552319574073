import Icon from '@ui-v2/core/Icon/Icon';
import Notification from '@ui-v2/core/Notification/Notification';
import useCmsTranslation from '@utils/hooks/useCmsTranslation';

export interface Props {
  stopDestination?: string;
  stopOrigin: string;
}

const ItineraryGroundTransitWarning = ({
  stopDestination,
  stopOrigin,
}: Props) => {
  const { t } = useCmsTranslation();

  return (
    <Notification variant="success">
      <Icon colour="icons.on.success" size={20} type="warningIcon" />

      {t(
        'ground_transit_info',
        `Your trip involves a transfer from ${stopOrigin} to ${stopDestination}. Please note that you must collect any checked baggage before proceeding to your connection`,
        {
          origin: stopOrigin,
          destination: stopDestination,
        },
      )}
    </Notification>
  );
};

export default ItineraryGroundTransitWarning;
