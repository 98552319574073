import { ReactNode } from 'react';
import useIsOnClient from '@utils/hooks/useIsOnClient';

const ClientExperimentWrapper = ({ children }: { children: ReactNode }) => {
  const isClient = useIsOnClient();

  // Render nothing on the server. Once on the client, render the children.
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return isClient ? <>{children}</> : null;
};

export default ClientExperimentWrapper;
