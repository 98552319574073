import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Vendors } from '@codegen/cmsTypes';
import { RouteFragment, Route } from '@codegen/gatewayUtils';
import { Language } from '@shared/types/enums';
import Box from '@ui-v2/core/Box/Box';
import Text from '@ui-v2/core/Text/Text';
import { getDuration } from '@utils/dateUtils';
import useCmsTranslation from '@utils/hooks/useCmsTranslation';
import { getStopDuration, getIsTrain } from '@utils/itineraryUtils';
import { boundHasOvernightStay, getTotalDuration } from '@utils/routeUtils';
import ItineraryConnectionInformation from './ItineraryConnectionInformation';
import ItineraryLeg from './ItineraryLeg';

export interface Props {
  directionId: 'outbound' | 'homebound';
  hasNoCheckedBagsConnection?: boolean;
  language: Language;
  premiumTierName?: string;
  residency: string;
  routes: RouteFragment[];
  showItineraryVendorLogos: boolean;
  showSelfConnectList?: boolean;
  showSelfTransfer?: boolean;
  title: string;
  toggleInformationModal: () => void;
  vendors: Vendors;
}

const Container = styled(Box)(
  ({ theme: { colours, spacings } }) => css`
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    padding: 0;
    color: ${colours.text.default};
    gap: ${spacings['24']}px;
  `,
);

const TitleContainer = styled.div(
  ({ theme: { spacings } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${spacings['4']}px;
  `,
);

const ItineraryDirection = ({
  directionId,
  hasNoCheckedBagsConnection,
  language,
  premiumTierName,
  residency,
  routes,
  showItineraryVendorLogos,
  showSelfConnectList,
  showSelfTransfer,
  title,
  toggleInformationModal,
  vendors,
}: Props) => {
  const { t } = useCmsTranslation();

  const [days, hours, minutes] = getDuration(getTotalDuration(routes));

  return (
    <Container px={16}>
      <TitleContainer>
        <Text as="span" variant="heading-4">
          {title}
        </Text>
        <Text as="span" colour="text.subdued">
          {`${t('journey_duration', 'Journey duration')}: `}
          {days
            ? t(
                '{{days}}d {{hours}}h {{minutes}}m',
                `${days}d ${hours}h ${minutes}m`,
                { days, hours, minutes },
              )
            : t('{{hours}}h {{minutes}}m', `${hours}h ${minutes}m`, {
                hours,
                minutes,
              })}
        </Text>
      </TitleContainer>

      <div>
        {routes.map((route, iRoute) => {
          const stopDuration = getStopDuration(
            routes,
            iRoute,
            route.legs.length - 1,
          );

          const stopOrigin = route.destination.name;
          const stopDestination = routes[iRoute + 1]?.origin.name;
          const hasOvernightStay =
            routes[iRoute + 1] &&
            boundHasOvernightStay([route, routes[iRoute + 1] as Route]);

          const nextLegIsTrain = routes[iRoute + 1]?.legs.some((leg) =>
            getIsTrain(leg.carrierType),
          );

          return (
            <React.Fragment key={route.id}>
              {route.legs.map((leg, iLeg) => (
                <ItineraryLeg
                  directionId={directionId}
                  iLeg={iLeg}
                  iRoute={iRoute}
                  key={leg.id}
                  language={language}
                  leg={leg}
                  residency={residency}
                  routes={routes}
                  showItineraryVendorLogos={showItineraryVendorLogos}
                  vendors={vendors}
                />
              ))}
              {stopDuration && (
                <ItineraryConnectionInformation
                  hasNoCheckedBagsConnection={hasNoCheckedBagsConnection}
                  hasOvernightStay={hasOvernightStay}
                  nextLegIsTrain={nextLegIsTrain}
                  premiumTierName={premiumTierName}
                  residency={residency}
                  showSelfConnectList={Boolean(
                    routes.length > 1 && showSelfConnectList,
                  )}
                  showSelfTransfer={showSelfTransfer}
                  stopDestination={stopDestination}
                  stopDuration={stopDuration}
                  stopOrigin={stopOrigin}
                  toggleInformationModal={toggleInformationModal}
                />
              )}
            </React.Fragment>
          );
        })}
      </div>
    </Container>
  );
};

export default ItineraryDirection;
