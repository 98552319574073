import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useCmsTranslation from '@utils/hooks/useCmsTranslation';
import { ReactComponent as PassengerIcon } from '../../assets/icons/running-passenger.svg';

const StyledPassengerIcon = styled(PassengerIcon)(
  ({ theme: { colours } }) => css`
    width: 15px;
    height: 15px;
    margin-right: 5px;
    fill: ${colours.icons.default};
  `,
);

const SectionContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
`;

const ItinerarySelfTransfer = () => {
  const { t } = useCmsTranslation();

  return (
    <SectionContainer>
      <StyledPassengerIcon />
      {t('Self-transfer', 'Self-transfer')}
    </SectionContainer>
  );
};

export default ItinerarySelfTransfer;
