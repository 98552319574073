import * as Sentry from '@sentry/nextjs';
import { Vendors } from '@codegen/cmsTypes';
import {
  ServiceFragment,
  IconConfigFragment,
  ImageWithConfigFragment,
} from '@codegen/cmsUtils';
import {
  AdditionalLuggage,
  OfferBundleServicesItem,
  SelectedSeat,
  ServiceClass,
  Summary,
} from '@codegen/offerAPI';
import { findVendorByIata } from '@ui/utils/vendorUtils';
import { TranslateCmsString } from '@utils/hooks/useCmsTranslation';
import { BundleService } from '@utils/sharedServiceUtils';

export const constructService = ({
  color,
  description,
  icon,
  name,
  serviceId,
}: ServiceFragment): BundleService => ({
  name: name?.value ?? '',
  iconUrl: icon?.url,
  color: color?.main,
  id: serviceId,
  description: description?.value,
});

export const luggageServiceClasses: Partial<ServiceClass[]> = [
  ServiceClass.luggage_cabin,
  ServiceClass.luggage_personal,
  ServiceClass.luggage_checked,
];

export const isLuggageService = (serviceClass: ServiceClass) =>
  luggageServiceClasses.includes(serviceClass);

export const constructCombinedService = ({
  cmsService,
  getFallbackIcon,
  offerService,
  t,
}: {
  cmsService?: ServiceFragment;
  getFallbackIcon: (
    iconIdentifier: keyof IconConfigFragment,
  ) => ImageWithConfigFragment | null;
  offerService: OfferBundleServicesItem | AdditionalLuggage | SelectedSeat;
  t: TranslateCmsString;
}): BundleService | undefined => {
  const hasPrice = 'price' in offerService;
  const hasQuantity = isLuggageService(offerService.service_class);

  const quantity =
    hasQuantity || hasPrice || offerService.quantity > 1
      ? offerService.quantity
      : undefined;

  const fallbackName = t(offerService.service_class, 'MISSING_STRING');

  if (!cmsService && fallbackName === 'MISSING_STRING') {
    Sentry.captureMessage(
      `No service config or fallback translation string for service: ${offerService.service_class}`,
    );
  }

  return {
    ...(cmsService && cmsService.name?.value
      ? {
          ...constructService(cmsService),
          name: t(cmsService.name.value, cmsService.name.value, {
            value: 'value' in offerService ? offerService.value : undefined,
          }),
        }
      : {
          name: t(offerService.service_class, offerService.service_class),
          iconUrl:
            getFallbackIcon(
              offerService.service_class as keyof IconConfigFragment,
            )?.asset.url ?? undefined,
        }),
    dimensions:
      'dimensions' in offerService ? offerService.dimensions : undefined,
    weight: 'weight' in offerService ? offerService.weight : undefined,
    quantity,
    price: hasPrice ? offerService.price : undefined,
    canDecrement:
      'can_decrement' in offerService ? offerService.can_decrement : undefined,
    canIncrement:
      'can_increment' in offerService ? offerService.can_increment : undefined,
    serviceClass: offerService.service_class,
    description: cmsService?.description?.value,
    passengerId:
      'passenger_id' in offerService ? offerService.passenger_id : undefined,
    id:
      'service_id' in offerService
        ? offerService.service_id
        : offerService.service_class,
    row: 'row' in offerService ? offerService.row : undefined,
    col: 'col' in offerService ? offerService.col : undefined,
  };
};

export const getCmsServiceFromOfferService = ({
  cmsServices,
  offerService,
}: {
  cmsServices?: ServiceFragment[];
  offerService: OfferBundleServicesItem;
}) => {
  return cmsServices?.find(
    (s) =>
      s.serviceId ===
        `${offerService.service_class}${
          'weight' in offerService ? `_${offerService.weight}` : ''
        }` || s.serviceId === offerService.service_class,
  );
};

export const constructCombinedServices = ({
  cmsServices = [],
  getFallbackIcon,
  offerServices,
  t,
}: {
  cmsServices?: ServiceFragment[];
  getFallbackIcon: (
    iconIdentifier: keyof IconConfigFragment,
  ) => ImageWithConfigFragment | null;
  offerServices: OfferBundleServicesItem[];
  t: TranslateCmsString;
}) => {
  return offerServices
    .map((offerService) => {
      const cmsService = getCmsServiceFromOfferService({
        offerService,
        cmsServices,
      });

      return constructCombinedService({
        cmsService,
        offerService,
        t,
        getFallbackIcon,
      });
    })
    .filter((service): service is BundleService => Boolean(service))
    .sort((firstBundleService, secondBundleService) => {
      const firstIndex =
        cmsServices.findIndex(
          (s) =>
            s.serviceId ===
              `${firstBundleService.id}${
                'weight' in firstBundleService
                  ? `_${firstBundleService.weight}`
                  : ''
              }` || s.serviceId === firstBundleService.id,
        ) || -1;

      const secondIndex =
        cmsServices.findIndex(
          (s) =>
            s.serviceId ===
              `${secondBundleService.id}${
                'weight' in secondBundleService
                  ? `_${secondBundleService.weight}`
                  : ''
              }` || s.serviceId === secondBundleService.id,
        ) || -1;

      return firstIndex >= secondIndex ? 1 : -1;
    });
};

export const getAdditionalServices =
  ({
    getFallbackIcon,
    summary,
    t,
    vendors,
  }: {
    getFallbackIcon: (
      iconIdentifier: keyof IconConfigFragment,
    ) => ImageWithConfigFragment | null;
    summary?: Summary;
    t: TranslateCmsString;
    vendors: Vendors;
  }) =>
  (legId: string) => {
    if (!summary) {
      return [];
    }

    return summary.leg_summaries
      .filter((legSummary) =>
        legSummary.legs.some((leg) => leg.leg_id === legId),
      )
      .flatMap((legSummary) => {
        const cmsServicesConfig = findVendorByIata(
          vendors,
          legSummary.legs[0]?.marketing_carrier.code ?? '',
        )?.vendorBookingConfig?.servicesConfig?.services;

        return legSummary.additional_services
          .filter((service) =>
            'leg_id' in service ? service.leg_id === legId : true,
          )
          .map((legService) => {
            const cmsService = cmsServicesConfig?.find(
              (service) => service.serviceId === legService.service_class,
            );

            const combinedService = constructCombinedService({
              offerService: legService,
              cmsService,
              t,
              getFallbackIcon,
            });

            return combinedService;
          });
      })
      .filter((service): service is BundleService => Boolean(service));
  };

export const getCombinedAdditionalServicesFromSummary = (
  services?: BundleService[],
) => {
  if (!services) {
    return [];
  }

  const flattenedServices = Object.values(services).flat();

  return flattenedServices.reduce<BundleService[]>((acc, service) => {
    const serviceKeysToCompare = ['serviceClass', 'weight'] as const;

    const shouldCombine = acc.find((s) =>
      serviceKeysToCompare.every((key) => s[key] === service[key]),
    );

    if (shouldCombine) {
      return acc.map((s) => {
        if (serviceKeysToCompare.some((key) => s[key] !== service[key])) {
          return s;
        }

        return combineServices({ s, service });
      });
    }

    return [...acc, service];
  }, []);
};

const combineServices = ({
  s,
  service,
}: {
  s: BundleService;
  service: BundleService;
}) => {
  const isSameService = s.id === service.id;

  return {
    ...s,
    quantity: (service.quantity || 0) + (isSameService ? 0 : s.quantity || 0),
    ...(s.price && {
      price: {
        ...s.price,
        amount: (s.price.amount || 0) + (service.price?.amount || 0),
      },
    }),
  };
};
