export interface SecureFieldStyles {
  cardNumber: string;
  cvv: string;
}

export type SecureFieldsOptions = {
  styles: SecureFieldStyles;
};

export type SecureFieldsFieldsConfig = {
  cardNumber: {
    inputType: string;
    placeholder?: string;
    placeholderElementId: string;
  };
  cvv: {
    inputType: string;
    placeholder?: string;
    placeholderElementId: string;
  };
};

export type SecureFieldsConfig = {
  fields: SecureFieldsFieldsConfig;
  merchantID: string;
};

export enum CardTypes {
  AMERICAN_EXPRESS = 'AMX',
  DINERS = 'DIN',
  DISCOVERY = 'DIS',
  JCB = 'JCB',
  MAESTRO = 'MAU',
  MASTERCARD = 'ECA',
  VISA = 'VIS',
}

export type CardInfoData = {
  cardInfo: {
    brand: string;
    country: string;
    issuer: string;
    type: 'credit' | 'debit';
    usage: string;
  };
  maskedCard: string;
  paymentMethod: CardTypes;
};

type OnCallbacks = {
  cardInfo: (data: CardInfoData) => void;
  change: (data: SecureFieldsData) => void;
  error: (data: SecureFieldsErrorData) => void;
  ready: () => void;
  success: (data: SecureFieldsSuccessData) => void;
  validate: (data: SecureFieldsData) => void;
};

export type SecureFields = {
  new (): SecureFields;
  clear: () => void;
  destroy: () => void;
  focus: () => void;
  getCardInfo: (callback: (data: CardInfoData) => void) => void;
  initTokenize: (
    merchantID: string,
    fields: SecureFieldsFieldsConfig,
    options?: SecureFieldsOptions,
  ) => void;
  on: <T extends keyof OnCallbacks, C extends OnCallbacks[T]>(
    type: T,
    callback: C,
  ) => void;
  setStyle: (field: string, style: string) => void;
  submit: () => void;
};

export type SecureFieldsData = {
  event: {
    field: 'cardNumber' | 'cvv' | 'expiryMonth' | 'expiryYear';
    type: 'focus' | 'blur' | 'autocomplete';
    value?: string;
  };
  fields: {
    cardNumber?: {
      isExcluded?: boolean;
      length: number;
      paymentMethod?: CardTypes;
      valid: boolean;
    };
    cvv?: {
      length: number;
      valid: boolean;
    };
  };
  hasErrors: boolean;
};

export type SecureFieldsSuccessData = {
  cardInfo: {
    brand: string;
    country: string;
    issuer: string;
    type: string;
    usage: string;
  };
  transactionId: string;
};

export type SecureFieldsErrorData =
  | {
      action: string;
      error: string;
    }
  | string;
