import { useStandaloneContentQuery } from '@codegen/cmsUtils';
import { useConstants } from '../ConstantContext';

const useStandaloneContent = (identifier: string) => {
  const { locale, partner } = useConstants();
  const { data, isLoading } = useStandaloneContentQuery({
    identifier,
    locale,
    namespace: partner,
  });

  const partnerContent = data?.allStandaloneContents.find(
    ({ namespace }) => namespace === partner,
  )?.content;

  const sharedContent = data?.allStandaloneContents.find(
    ({ namespace }) => namespace === 'shared',
  )?.content;

  return {
    isLoading,
    content: partnerContent ?? sharedContent,
  };
};

export default useStandaloneContent;
