import { useCallback, useMemo } from 'react';
import { Vendors } from '@codegen/cmsTypes';
import { useAllVendorsQuery } from '@codegen/cmsUtils';
import {
  getIataStringPattern,
  getExcludedCardTypes,
} from '@ui/utils/vendorUtils';
import { getVendorLink } from '@utils/vendorUtils';
import { useConstants } from '../ConstantContext';
import { useSettings } from '../SettingsContext';

const useVendors = ({ iatas }: { iatas: string[] }) => {
  const { locale } = useConstants();
  const { residency } = useSettings();

  const { data, isError, isLoading } = useAllVendorsQuery(
    {
      iataPattern: getIataStringPattern(iatas),
      locale,
    },
    { enabled: iatas.length > 0 },
  );

  const vendors = useMemo(
    () =>
      (data?.allVendors ?? []).map(
        ({
          aboutFaresLinks,
          baggageAllowanceLinks,
          manageBookingLinks,
          menuDetailsLinks,
          ...rest
        }) => ({
          ...rest,
          aboutFaresLink: getVendorLink({
            links: aboutFaresLinks,
            residency,
            locale,
          }),
          baggageAllowanceLink: getVendorLink({
            links: baggageAllowanceLinks,
            residency,
            locale,
          }),
          manageBookingLink: getVendorLink({
            links: manageBookingLinks,
            residency,
            locale,
          }),
          menuDetailsLink: getVendorLink({
            links: menuDetailsLinks,
            residency,
            locale,
          }),
        }),
      ),
    [data?.allVendors, locale, residency],
  ) as Vendors;

  const excludedCardTypes = getExcludedCardTypes(vendors);

  const getSingleVendor = useCallback(
    (iata?: string) =>
      vendors.find((vendor) => iata && vendor.iata?.includes(iata)),
    [vendors],
  );

  return {
    vendors,
    isLoading,
    isError,
    getSingleVendor,
    excludedCardTypes,
  };
};

export default useVendors;
