import dayjs from 'dayjs';
import Trans from 'next-translate/Trans';
import { Vendors } from '@codegen/cmsTypes';
import {
  BundleFragment,
  IconConfigFragment,
  ImageWithConfigFragment,
} from '@codegen/cmsUtils';
import {
  AdditionalLuggage,
  BookedAdditionalLoungeAccess,
  Leg,
  LegGroupSummary,
  LegGroupSummaryAdditionalServicesItem,
  LegGroupSummaryIncludedServicesItem,
  SelectedSeat,
  ServiceClass,
  Summary,
  SummaryOtherServicesItem,
} from '@codegen/offerAPI';
import { Language, RUNTIME_ENV } from '@shared/types/enums';
import { findVendorByIata } from '@ui/utils/vendorUtils';
import TranslatedLink from '@ui-v2/core/Link/Link';
import { removeDuplicates } from '@utils/arrayUtils';
import { getTimeStringFromDayjs, toUTCLocaleString } from '@utils/dateUtils';
import { TranslateCmsString } from '@utils/hooks/useCmsTranslation';
import { BundleService } from '@utils/sharedServiceUtils';
import { getCityNamesFromLegs } from './bookingUtils';
import { constructCombinedServices } from './serviceUtils';

export const getBundleName = ({
  bundleCode,
  bundleConfig,
  legs,
}: {
  bundleCode: string;
  bundleConfig?: BundleFragment[];
  legs?: Leg[];
}) => {
  const selectedBundle = bundleConfig?.find((bundle) =>
    bundle.bundleId?.includes(bundleCode),
  );

  const fallbackAirlines = legs?.map(
    (itinerary) => itinerary.marketing_carrier.name,
  );

  const fallbackAirlineFiltered = [...new Set(fallbackAirlines)].join(', ');

  return selectedBundle?.name ?? (fallbackAirlineFiltered || bundleCode);
};

// returns the itinerary as it is displayed in the summary
export const getItineraryItems = (legs: Leg[]) => {
  return {
    leg_id: legs[0]?.leg_id,
    origin: legs[0]?.origin,
    destination: legs[legs.length - 1]?.destination,
    is_outbound: legs[0]?.is_outbound,
    marketing_carrier: legs[0]?.marketing_carrier,
    operating_carrier: legs[0]?.operating_carrier,
    flight_number: legs[0]?.flight_number,
    departure: legs[0]?.departure,
    arrival: legs[legs.length - 1]?.arrival,
  };
};

export const getIatasFromSummary = (summary?: {
  leg_summaries: LegGroupSummary[];
}) => {
  if (!summary) {
    return [];
  }

  return removeDuplicates(
    summary.leg_summaries.reduce<string[]>((iatas, legSummary) => {
      const legIatas = legSummary.legs
        .reduce<string[]>((legAcc, leg) => {
          if (legAcc.includes(leg.marketing_carrier.code)) {
            return legAcc;
          }

          return [...legAcc, leg.marketing_carrier.code];
        }, [])
        .filter((iata) => !iatas.includes(iata));

      return [...iatas, ...legIatas];
    }, []),
  );
};

export interface BundleInfo {
  code: string;
  includedServices: BundleService[];
  itinerary: ReturnType<typeof getItineraryItems>;
  legItineraryName: string;
  name: string;
}

export const getSelectedBundlesFromSummary = ({
  getFallbackIcon,
  summary,
  t,
  vendors,
}: {
  getFallbackIcon: (
    iconIdentifier: keyof IconConfigFragment,
  ) => ImageWithConfigFragment | null;
  summary?: Summary;
  t: TranslateCmsString;
  vendors: Vendors;
}) => {
  if (!summary) {
    return [];
  }

  const bundleCodes = summary.leg_summaries.reduce<string[]>(
    (acc, leg) =>
      acc.includes(leg.bundle.code) ? acc : [...acc, leg.bundle.code],
    [],
  );

  return bundleCodes
    .map((bundleCode) => {
      const bundleConfig = Object.values(vendors).find((vendor) =>
        vendor.vendorBookingConfig?.bundles.find((bundle) =>
          bundle.bundleId?.includes(bundleCode),
        ),
      )?.vendorBookingConfig?.bundles;

      const legSummary = summary.leg_summaries.find(
        (leg) => leg.bundle.code === bundleCode,
      );

      if (legSummary) {
        const itinerary = getItineraryItems(legSummary.legs);

        const cmsServices = findVendorByIata(
          vendors,
          itinerary.marketing_carrier?.code,
        )?.vendorBookingConfig?.servicesConfig?.services;

        const includedServices = constructCombinedServices({
          offerServices: legSummary.included_services,
          cmsServices: cmsServices || [],
          t,
          getFallbackIcon,
        });

        const legItineraryName = getCityNamesFromLegs(legSummary.legs);

        return {
          code: bundleCode,
          name: getBundleName({
            bundleCode,
            bundleConfig,
            legs: legSummary.legs,
          }),
          legItineraryName,
          includedServices,
          itinerary,
        };
      }

      return null;
    })
    .filter((item): item is BundleInfo => Boolean(item));
};

export const getOtherServiceName = ({
  legGroups,
  protectGroupName,
  selectedTierName,
  service,
  t,
}: {
  legGroups?: LegGroupSummary[];
  protectGroupName?: string;
  selectedTierName?: string;
  service: SummaryOtherServicesItem;
  t: TranslateCmsString;
}) => {
  switch (service.service_class) {
    case 'dohop_service_fee':
      return selectedTierName;
    case 'cancellation_protection':
      return protectGroupName;

    case 'lounge_access': {
      const serviceLeg = legGroups
        ?.flatMap((leg) => leg.legs)
        .find(
          (leg) =>
            leg.flight_number ===
            (service as BookedAdditionalLoungeAccess).flight_number,
        );

      return `${t('airport_lounge_access', 'Airport Lounge Access')} ${t(
        'at',
        'at',
      )} ${serviceLeg?.origin.airport_name} (${
        serviceLeg?.origin.airport_iata
      })`;
    }
    default:
      return t(service.service_class, service.service_class);
  }
};

export const getOtherServiceDescription = ({
  language,
  orderId,
  residency,
  service,
  t,
}: {
  language: Language;
  orderId?: string;
  residency: string;
  service: SummaryOtherServicesItem;
  t: TranslateCmsString;
}) => {
  switch (service.service_class) {
    case 'lounge_access': {
      const lounge = service as BookedAdditionalLoungeAccess;
      const loungeEntry = dayjs(lounge.lounge_entry);

      return [
        ...(lounge.reference_id
          ? [
              {
                label: t('reference_id', 'Reference Id'),
                value: lounge.reference_id,
              },
            ]
          : []),
        ...(lounge.lounge_entry
          ? [
              {
                label: t('lounge_arrival_time', 'Lounge arrival time'),
                value: `${toUTCLocaleString({
                  date: loungeEntry.toDate(),
                  locale: language,
                  residency,
                  options: {
                    day: '2-digit',
                    month: 'short',
                  },
                })} ${t('at', 'at')} ${getTimeStringFromDayjs(
                  loungeEntry,
                  language,
                )}`,
              },
            ]
          : []),
      ];
    }
    case 'cancellation_protection':
      if (!orderId) {
        return null;
      }

      return [
        {
          label: t('reference_id', 'Reference Id'),
          value: orderId,
        },

        {
          label: t('reference_id', 'Reference Id'),
          value: (
            <Trans
              components={[
                <TranslatedLink
                  href={`https://${
                    process.env.NEXT_PUBLIC_RUNTIME_ENV ===
                    RUNTIME_ENV.production
                      ? ''
                      : 'test.'
                  }form.refundable.me/forms/refund?bookingReference=${orderId}&memberId=839`}
                  key="refund-link"
                  size="small"
                />,
              ]}
              defaultTrans="Click <0>here</0> to request a refund"
              i18nKey="refund_request"
            />
          ),
        },
      ];
    default:
      return null;
  }
};

/**
 * For a given service class type,
 * return all instances of that bag from both included and additional services
 */
export const getBagsOfType = ({
  additionalServices,
  includedServices,
  serviceClass,
}: {
  additionalServices: LegGroupSummaryAdditionalServicesItem[];
  includedServices: LegGroupSummaryIncludedServicesItem[];
  serviceClass: ServiceClass;
}) =>
  [
    includedServices.find(
      (service): service is AdditionalLuggage =>
        service.service_class === serviceClass,
    ),
    ...additionalServices.filter(
      (service): service is AdditionalLuggage =>
        service.service_class === serviceClass,
    ),
  ].filter((x): x is AdditionalLuggage => !!x);

const seatServiceClasses: string[] = [
  ServiceClass['seat_reservation'],
  ServiceClass['seat_selection'],
  ServiceClass['seat_selection_any'],
  ServiceClass['seat_selection_economy'],
  ServiceClass['seat_selection_emergency_exit'],
  ServiceClass['seat_selection_extra_legroom'],
  ServiceClass['seat_selection_first_row'],
  ServiceClass['seat_selection_mismatch'],
  ServiceClass['seat_selection_plus'],
  ServiceClass['seat_selection_standard'],
  ServiceClass['seat_selection_standard_upfront'],
  ServiceClass['seat_selection_upfront'],
];

/**
 * For a given leg summary and passenger id; return some seat info
 * This is used to display info about which seat the user is sitting in,
 * whether the user has a free seat available but has not selected it etc
 */
export const getSeatSelectionInfo = ({
  legSummary,
  passengerId,
}: {
  legSummary: LegGroupSummary;
  passengerId: string;
}) => {
  const combinedServices = [
    ...legSummary.additional_services.filter(
      (x) => x.passenger_id === passengerId,
    ),
    ...legSummary.included_services,
  ];

  // Try to find the service item that has an assigned seat attached to it
  const seatSelection = combinedServices.find(
    (service): service is SelectedSeat =>
      service.service_class === ServiceClass.seat_selection &&
      'col' in service &&
      'row' in service,
  );

  // Try to find any other seat service item that does not include an assigned seat
  // When a user has a seat included with his/her ticket, we should find one of these
  const bundleSeatSelection = combinedServices.find(
    (service) =>
      seatServiceClasses.includes(service.service_class) &&
      !('col' in service) &&
      !('row' in service),
  );

  /**
   * If we find a bundle seat selection but no actual seat selection
   * We assume that the user has a bundle included seat available but
   * has not picked a seat
   */
  const hasNotSelectedAnIncludedSeat =
    !seatSelection && Boolean(bundleSeatSelection);

  return {
    seatSelection,
    bundleSeatSelection,
    hasNotSelectedAnIncludedSeat,
  };
};
