import { Vendors } from '@codegen/cmsTypes';
import { ItineraryFragment } from '@codegen/gatewayUtils';
import { Language } from '@shared/types/enums';
import Box from '@ui-v2/core/Box/Box';
import Button from '@ui-v2/core/Button/Button';
import Modal from '@ui-v2/core/Modal/Modal';
import useCmsTranslation from '@utils/hooks/useCmsTranslation';
import { offerhasNoCheckedBagsConnection } from '@utils/itineraryUtils';
import NoCheckedBagsBadge from '../Offer/NoCheckedBagsBadge';
import Itinerary from './Itinerary';

export interface Props {
  isOpen: boolean;
  itinerary?: ItineraryFragment;
  language: Language;
  onNoCheckedBagsClick: () => void;
  premiumTierName?: string;
  residency: string;
  showItineraryVendorLogos: boolean;
  showSelfConnectList: boolean;
  toggleInformationModal: () => void;
  toggleItineraryModal: () => void;
  vendors?: Vendors;
}

const ItineraryModal = ({
  isOpen,
  itinerary,
  language,
  onNoCheckedBagsClick,
  premiumTierName,
  residency,
  showItineraryVendorLogos,
  showSelfConnectList,
  toggleInformationModal,
  toggleItineraryModal,
  vendors,
}: Props) => {
  const { t } = useCmsTranslation();

  const hasNoCheckedBagsConnection = offerhasNoCheckedBagsConnection(itinerary);

  return (
    <Modal
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={false}
      footer={
        <Box display="flex" flex={1} justifyContent="space-between">
          {hasNoCheckedBagsConnection && (
            <NoCheckedBagsBadge onClickBadge={onNoCheckedBagsClick} />
          )}
          <Button
            ml="auto"
            onClick={toggleItineraryModal}
            size="small"
            variant="secondary"
          >
            {t('Close', 'Close')}
          </Button>
        </Box>
      }
      id="basket-itinerary-modal"
      isOpen={isOpen}
      mobileSize="full-screen"
      onOpenChange={toggleItineraryModal}
      title={t('Your itinerary', 'Your itinerary')}
    >
      {itinerary && vendors && (
        <Itinerary
          hasNoCheckedBagsConnection={hasNoCheckedBagsConnection}
          itinerary={itinerary}
          language={language}
          premiumTierName={premiumTierName}
          residency={residency}
          showFareRules={false}
          showItineraryVendorLogos={showItineraryVendorLogos}
          showSelfConnectList={showSelfConnectList}
          showSelfTransfer
          toggleInformationModal={toggleInformationModal}
          vendors={vendors}
        />
      )}
    </Modal>
  );
};

export default ItineraryModal;
